<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-27 23:03:54
 * @LastEditors: joker 871180637@qq.com
 * @LastEditTime: 2022-07-21 18:14:24
 * @FilePath: \show-before\src\views\Footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="footer">
    <div class="wrap">
      <div class="foot_nav">
        <div class="foot-div">
          <div class="title">PRODUCTS</div>
          <div class="text" v-for="item in list" :key="item" @click="goPath(item.category_id)">{{ item.category_name }}11</div>
        </div>
        <div class="foot-div">
          <div class="title">ABOUT US</div>
          <div class="text" @click="goAbout('AboutUs')">About Us</div>
          <!-- <div class="text">Privacy</div> -->
          <div class="text" @click="goAbout('Warranty')">Warranty</div>
          <!-- <div class="text">Copyright</div> -->
          <div class="super">
            <a href="https://wa.me/8618819429796" target="_blank ">
              <img src="@/assets/wa.jpg" alt="" style="width: 40px; height: 40px" />
            </a>
            <a href="https://www.instagram.com/josephlai_lcd/" target="_blank ">
              <img src="@/assets/lcd.jpg" alt="" style="width: 40px; height: 40px" />
            </a>
          </div>
        </div>
        <!-- <div class="foot-div">
          <div class="title">CUSTOMER SERVICE</div>
          <div class="text">Contact Us</div>
          <div class="text">Question & Answer(FAQ)</div>
          <div class="text">Order Proces</div>
     
        </div> -->
        <div class="foot-div">
          <div class="title">CONTACT US</div>
          <a href="mailto:quintallai@qq.com" class="email" title="Info@qiwei.com.cn">quintallai@qq.com</a>
          <!-- <div class="email">For Apple IPhone Repair Parts</div> -->
          <div class="addr">Room&ensp;204,No.3&ensp;East&ensp;Xingye Avenue,Nancun&ensp;Town,Panyu&ensp;District,Guangzhou,Guangdong province,China</div>
        </div>
      </div>
      <div class="copyright">QIWEI © All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    goAbout(name) {
      this.$router.push({ name })
    },
    goPath(category_id) {
      this.$router.push({
        path: "/product-list",
        query: {
          good_category_parent_id: category_id,
        },
      })
    },
  },

  components: {},

  computed: {},
}
</script>
<style lang="scss" scoped>
.footer {
  background: #f9f9f9;
  padding-top: 25px;
  padding-bottom: 50px;
  .wrap {
    width: 1200px;
    margin: 0 auto;
    zoom: 1;
    .foot_nav {
      text-align: justify;
      display: flex;
      justify-content: space-around;
      .foot-div {
        width: 25%;
        vertical-align: top;
        margin-left: 10px;
        .title {
          height: 54px;
          line-height: 54px;
          font-size: 16px;
          color: #333;
          text-transform: uppercase;
          font-weight: bold;
        }
        .text {
          cursor: pointer;
          display: block;
          height: 25px;
          line-height: 25px;
          overflow: hidden;
          color: #2f99e3;
          font-size: 14px;
        }
        .email {
          padding-left: 30px;
          line-height: 30px;
          color: #999;
          text-decoration: none;
          background: url(@/assets/foot_contact.png) no-repeat left 4px;
        }
        .addr {
          padding-left: 30px;
          margin-top: 10px;
          line-height: 30px;
          font-size: 16px;
          color: #999;
          background: url(@/assets/foot_contact.png) no-repeat left -102px;
        }
        .super {
          display: flex;
          a {
            margin: 10px 10px 0 0;
          }
        }
      }
    }
  }
  .copyright {
    padding: 50px 0 25px;
    color: #999;
    font-size: 16px;
  }
}
</style>
