<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-25 20:58:42
 * @LastEditors: joker 871180637@qq.com
 * @LastEditTime: 2022-07-19 22:09:19
 * @FilePath: \show-before\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div>
      <Header :list="list" @goPath="goPath" @goCategory="goCategory"></Header>
    </div>
    <div>
      <router-view />
    </div>
    <div>
      <Footer :list="categoryList"></Footer>
    </div>
  </div>
</template>
<script>
import Header from "@/views/header.vue"
import Footer from "@/views/Footer.vue"

export default {
  data() {
    return {
      list: [
        { name: "HOME", showClass: false, path: "Home" },
        { name: "PRODUCTS", showClass: false, categoryList: [], path: "ProductList" },
        // { name: "ORDER PROCES", path: "", showClass: false, path: "/custom-order" },
        { name: "ABOUT US", path: "AboutUs", showClass: false },
        // { name: "CUSTOMER SERVICE", path: "", showClass: false },
      ],
      categoryList: [],
    }
  },
  mounted() {
    // window.SeoUpdate("全息通-会员中心-注册11111111111111SADAWDASDADASDADAWDASDA", "全息通-会员中心-注册", "全息通-会员中心-注册")
    this.getCategory()
  },
  methods: {
    getCategory() {
      this.$api.home.getHome({
        data: { type: 1 },
        success: (res) => {
          this.list[1].categoryList = res.header
          this.categoryList = res.footer
        },
      })
    },
    goCategory(category_id) {
      this.$router.push({
        path: "/product-list",
        query: {
          good_category_parent_id: category_id,
        },
      })
    },
    goPath(name) {
      this.$router.push({ name })
    },
  },

  components: { Header, Footer },

  computed: {},
}
</script>
<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
.mouse-cover-canvas {
  width: 350px;
  height: 350px;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
