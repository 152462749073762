/*
 * @Author: joker 871180637@qq.com
 * @Date: 2022-06-25 20:58:42
 * @LastEditors: joker 871180637@qq.com
 * @LastEditTime: 2022-07-18 21:21:40
 * @FilePath: \show-before\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-25 20:58:42
 * @LastEditors: joker 871180637@qq.com
 * @LastEditTime: 2022-06-28 17:51:29
 * @FilePath: \show-before\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "about" */ '@/views/HomeView.vue')
  },
  {
    path: "/product",
    name: 'Product',
    component: () => import("@/views/product/Product.vue"),
    children: [{
      name: 'ProductList',
      path: "/product-list",
      component: () => import("@/views/product/ProductList.vue"),
    }, {
      name: 'ProductDetail',
      path: "/product-detail/:good_id",
      component: () => import("@/views/product/ProductDetail.vue"),
    }, {
      name: 'ProductInquiry',
      path: "/product-inquiry/:good_id",
      component: () => import("@/views/product/ProductInquiry.vue"),
    }]
  },
  {
    path: "/custom",
    name: 'Custom',
    component: () => import("@/views/custom/Custom.vue"),
    children: [{
      name: 'ContactUs',
      path: "/custom-contact",
      component: () => import("@/views/custom/ContactUs.vue"),
    }, {
      name: 'Order',
      path: "/custom-order",
      component: () => import("@/views/custom/Order.vue"),
    }, {
      name: 'Question',
      path: "/custom-question",
      component: () => import("@/views/custom/Question.vue"),
    }]
  }, {
    path: "/about",
    name: 'About',
    component: () => import("@/views/about/About.vue"),
    children: [{
      path: "/about-us",
      name: 'AboutUs',
      component: () => import("@/views/about/AboutUs.vue"),
    }, {
      path: "/about-warranty",
      name: 'Warranty',
      component: () => import("@/views/about/Warranty.vue"),
    }]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router