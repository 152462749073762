<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-25 21:33:22
 * @LastEditors: joker 871180637@qq.com
 * @LastEditTime: 2022-07-20 17:19:23
 * @FilePath: \show-before\src\views\header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="header">
    <div><img src="@/assets/home.png" width="130" height="130" /></div>
    <div class="route">
      <div v-for="(item, index) in list" :key="item" class="route-div trans3" @mouseenter="addClass(index)" @mouseleave="removeClass(index)" @click.stop="goPath(item.path)">
        <span class="route-item" :class="item.showClass ? 'show-route-item' : ''"
          >{{ item.name }}
          <div class="products trans3" v-if="index == 1">
            <div class="products-item" v-for="category in item.categoryList" :key="category.category_id" @click="goCategory(category.category_id)">
              <span class="products-item-text">{{ category.category_name }}</span>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { list: Array },
  data() {
    return {}
  },
  mounted() {
    // this.getCategory()
  },
  methods: {
    getCategory() {
      this.$api.home.getHome({
        data: { type: 1 },
        success: (res) => {
          this.list[1].categoryList = res.category
        },
      })
    },
    goCategory(category_id) {
      this.$emit("goCategory", category_id)
    },
    goPath(path) {
      this.$emit("goPath", path)
    },
    addClass(index) {
      this.list[index].showClass = true
    },
    removeClass(index) {
      this.list[index].showClass = false
    },
  },

  components: {},

  computed: {},
}
</script>
<style lang="scss" scoped>
.trans3 {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.header {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  align-items: center;
  // justify-content: space-between;
  .route {
    // height: 90px;
    display: flex;
    justify-content: space-around;
    margin-left: 600px;
    .route-div {
      justify-content: space-around;
      // box-sizing: border-box;
      display: inline-block;
      margin: 0 8px;
      position: relative;
      z-index: 100;

      .route-item {
        cursor: pointer;
        position: relative;
        display: block;
        text-decoration: none;
        height: 100px;
        line-height: 100px;
        padding: 0 20px;
        font-size: 14px;
        text-align: center;
        color: #333;
        text-transform: uppercase;
        font-weight: bold;
        .products {
          width: 0px;
          padding-top: 10px;
          padding-bottom: 13px;
          background: rgba(47, 153, 227, 0.8);
          position: absolute;
          top: 80px;
          z-index: 100;
          left: 50%;
          margin-left: 0;
          .products-item:first-child {
            border-top: none;
          }
          .products-item {
            position: relative;
            margin: 0 11px;
            border-top: 1px dotted #73bbeb;
            text-align: left;
            .products-item-text {
              color: #666666;
              display: block;
              height: 45px;
              overflow: hidden;
              font-weight: normal;
              line-height: 45px;
              text-transform: capitalize;
              text-indent: 12px;
              text-decoration: none;
              font-size: 14px;
              color: white;
            }
          }
        }
      }

      .route-item:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        width: 0;
        height: 28px;
        margin: auto;
        transition: all 0.3s;
        -moz-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        border: 1px solid transparent;
      }

      .show-route-item {
        // border: 1px solid #2f99e3;
        // box-sizing: border-box !important;

        // border-radius: 5px;
        color: #2f99e3;
      }
    }
    .route-div:hover .route-item:before {
      width: 99%;
      border: 1px solid #2f99e3;
      border-radius: 5px;
    }
    .route-div:hover .products {
      width: 234px;
      margin-left: -120px;
    }
  }
}
</style>
