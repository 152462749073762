/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-25 20:58:42
 * @LastEditors: joker 871180637@qq.com
 * @LastEditTime: 2022-07-19 22:23:02
 * @FilePath: \show-before\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus'
import App from '@/App.vue'
import '@/assets/css/style.scss'
import {
  createApp
} from 'vue'
// import App from './App.vue'
import './registerServiceWorker'
import axios from '@/api/index'
import router from './router'
const app = createApp(App)
app.config.globalProperties.$api = axios
app.config.globalProperties.$message = ElementPlus.ElMessage

document.title = 'QIWEI｜Wholesaler of LCD Screen Repair Parts｜Phone LCD Parts- www.qiweiparts.com.cn'
// window.SeoUpdate = function (SeoTitle, SeoKeywords, SeoDescription) {
//   let _headDom = '',
//     _title = '',
//     _meta = '';

//   _headDom = document.getElementsByTagName('head')[0]; //获取head节点
//   _title = _headDom.getElementsByTagName("title")[0]; //获取head节点下的title节点
//   _meta = _headDom.getElementsByTagName("meta"); //获取head节点下的meta节点，它一般是一个数组

//   _title.innerText = SeoTitle;
//   for (let index = 0; index < _meta.length; index++) {
//     switch (_meta[index].name) {
//       case 'keywords':
//         _meta[index].content = SeoKeywords;
//         break;
//       case 'description':
//         _meta[index].content = SeoDescription;
//         break;

//       default:
//         break;
//     }
//   }
// }


app.use(ElementPlus)
app.use(router)
app.mount('#app')