/*
 * @Author: your name
 * @Date: 2020-12-08 11:31:23
 * @LastEditTime: 2022-07-01 16:08:05
 * @LastEditors: joker 871180637@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \vue-admin-template\src\plugins\request.js
 */
import axios from "axios";
// import router from '../router/index'
// import { MessageBox, Message } from "element-ui";
// // import store from "@/store";
// import auth from "@/utils/auth";
// import {
//   Loading
// } from "element-ui";
// let loadingInstance = null
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// request interceptor
service.interceptors.request.use(
  config => {
    // loadingInstance = Loading.service({
    //   text: '请求中'
    // });
    // do something before request is sent
    // let token = auth.getToken();
    // if (token) {
    //   config.headers["token"] = token;
    // }
    return config;
  },
  error => {
    // do something with request error
    // console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // loadingInstance.close()

    let code = response.data.code
    // if (code == 3505 || code == 5000 || code == 5001 || code == 5002) {
    //   router.push({
    //     path: '/login'
    //   })
    // }
    return response;

    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 20000) {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
  },
  error => {
    // loadingInstance.close()
    // if (error.response) {
    // switch (error.response.status) {
    //   case 401:
    //     // 401 清除token信息并跳转到登录页面
    //     sessionStorage.removeItem("adminToken");
    //     // 只有在当前路由不是登录页面才跳转
    //     router.currentRoute.path !== "/login" &&
    //       router.replace({
    //         path: "/login",
    //         query: {
    //           redirect: router.currentRoute.path
    //         }
    //       });
    // }
    // }
    // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
    return Promise.reject(error);
  },

);

export default service;