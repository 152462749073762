/*
 * @Author: your name
 * @Date: 2020-12-08 10:53:57
 * @LastEditTime: 2022-07-20 20:01:54
 * @LastEditors: joker 871180637@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \vue-admin-template\src\utils\http.js
 */
import request from "./axios";
import {
  ElMessage
} from "element-plus";
const qs = require("qs");

function post(url, data) {
  let params =
    data.type == "array" ?
    qs.stringify(data.data, {
      arrayFormat: "repeat"
    }) :
    Object.prototype.toString.call(data.data) == "[object FormData]" ?
    data.data :
    qs.stringify(data.data);

  let headers = data.headers ? data.headers : {
    "Content-Type": "application/x-www-form-urlencoded"
  }
  // data.type == "form-data" ? {
  //   headers: {
  //     "Content-Type": "multipart/form-data"
  //   }
  // } :
  // data.headers;
  //   let r = axios.post(data.url, params, headers);
  let r = request({
    url,
    method: "post",
    headers,
    data: params
  });
  // 请求
  r.then(response => {
    try {
      // 成功回调函数 (100为成功回调)
      let showMsg = response.data.showMsg
      if (response.data.code == 100) {
        if (data.success) data.success(response.data.data, response);
        // 后台返回是否要显示提示
        if (showMsg) {
          ElMessage({
            type: "success",
            message: response.data.msg
          });
        }
      } else {
        if (showMsg) {
          // console.log(2)
          ElMessage.error(response.data.msg);
        }
        if (data.error) data.error(response.data.data, response);
      }
      if (data.complete) data.complete(response.data.data, response);
    } catch (e) {
      // console.log(e)
      ElMessage.error(response.data.msg);
    }

    // try {
    //   if (response.data.message == "succ") {
    //     // 成功回调函数
    //     if (data.success) data.success(response.data.result, response);
    //   } else if (response.data.message == "error") {
    //     if (!data.closeErrInfo) message.error(response.data.result);
    //     // 失败的回调函数
    //     if (data.error) data.error(response.data.result, response);
    //     if (data.allError) data.allError(response);
    //   } else if (response.data.message == "info") {
    //     if (!data.closeInfo) message.info(response.data.result);
    //     if (data.info) data.info(response.data.result, response);
    //   } else if (response.data.message == "warning") {
    //     if (!data.closeWarningInfo) message.info(response.data.result);
    //     if (data.warning) data.warning(response.data.result, response);
    //   }
    //   // 请求成功的回调
    //   if (data.then) data.then(response);
    //   if (data.finally) data.finally();
    // } catch (e) {
    //   console.error(e);
    //   if (data.finally) data.finally();
    //   if (data.allError) data.allError(e);
    // }
  });

  // 异常
  // r.catch(error => {
  //   console.log('error++++++++++++++++++++', error)
  //   if (error == 'Error: Network Error') {
  //     ElMessage.error('网络错误')
  //     return
  //   }
  //   // console.error(error);
  //   if (data.complete) data.complete(error);
  //   // 请求失败的回调函数
  //   if (data.catch) data.catch(error);
  //   if (data.allError) data.allError(error);
  //   if (!data.closeCodeInfo) {
  //     if (error.response && error.response.status) {
  //       // 状态码处理
  //       switch (error.response.status) {
  //         case 401:
  //           break;
  //         case 404:
  //           ElMessage.error("请求错误");
  //           break;
  //         case 403:
  //           ElMessage.error("无权访问");
  //           break;
  //         case 413:
  //           ElMessage.error("请求实体内容过大");
  //           break;
  //         case 500:
  //           ElMessage.error("系统异常");
  //           break;
  //         case 504:
  //           ElMessage.error("无法连接服务器");
  //           break;
  //         default:
  //           ElMessage.error("发生错误, errorStatus" + error.response.status);
  //           break;
  //       }
  //     } else if (error.code) {
  //       switch (error.code) {
  //         case "ECONNABORTED":
  //           ElMessage.error("请求超时, 网络不给力哦");
  //           break;
  //         default:
  //           ElMessage.error("发生错误, errorCode" + error.code);
  //           break;
  //       }
  //     }
  //   }
  // });
}


// function get(url, data) {
//   let params =
//     data.type == "array" ?
//     qs.stringify(data.data, {
//       arrayFormat: "repeat"
//     }) :
//     Object.prototype.toString.call(data.data) == "[object FormData]" ?
//     data.data :
//     qs.stringify(data.data);
//   let headers =
//     data.type == "form-data" ? {
//       headers: {
//         "Content-Type": "multipart/form-data"
//       }
//     } :
//     data.headers;
//   //   let r = axios.post(data.url, params, headers);
//   let r = request({
//     url,
//     method: "get",
//     params: data.data
//   });
//   // 请求
//   r.then(response => {
//     try {
//       // 成功回调函数 (100为成功回调)
//       if (response.data.code == 100) {
//         if (data.success) data.success(response.data.data, response);
//         // 后台返回是否要显示提示
//         if (response.data.showMsg) {
//           Message({
//             type: "success",
//             message: response.data.msg
//           });
//         }
//       } else {
//         if (response.data.showMsg) {

//           Message.error(response.data.msg);
//         }
//         if (data.error) data.error(response.data.data, response);
//       }
//       if (data.complete) data.complete(response.data.data, response);
//     } catch (e) {
//       Message.error(response.data.msg);
//     }
//   });

//   // 异常
//   r.catch(error => {
//     if (error == 'Error: Network Error') {
//       Message.error('网络错误')
//       return
//     }
//     // console.error(error);
//     if (data.complete) data.complete(error);
//     // 请求失败的回调函数
//     if (data.catch) data.catch(error);
//     if (data.allError) data.allError(error);
//     if (!data.closeCodeInfo) {
//       if (error.response && error.response.status) {
//         // 状态码处理
//         switch (error.response.status) {
//           case 401:
//             break;
//           case 404:
//             Message.error("请求错误");
//             break;
//           case 403:
//             Message.error("无权访问");
//             break;
//           case 413:
//             Message.error("请求实体内容过大");
//             break;
//           case 500:
//             Message.error("系统异常");
//             break;
//           case 504:
//             Message.error("无法连接服务器");
//             break;
//           default:
//             Message.error("发生错误, errorStatus" + error.response.status);
//             break;
//         }
//       } else if (error.code) {
//         switch (error.code) {
//           case "ECONNABORTED":
//             Message.error("请求超时, 网络不给力哦");
//             break;
//           default:
//             message.error("发生错误, errorCode" + error.code);
//             break;
//         }
//       }
//     }
//   });
// }
export default {
  post,
  // get
};