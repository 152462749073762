/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-26 23:20:10
 * @LastEditors: joker 871180637@qq.com
 * @LastEditTime: 2022-07-16 16:02:17
 * @FilePath: \show-before\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/plugins/http";

// 获取首页数据
const getHome = function (data) {
  return request.post("/home/getHome", data);
};
//商品详情
const getDetail = function (data) {
  return request.post("/home/good/getGoodDetail", data);
};
// 获取分类
const getCategory = function (data) {
  return request.post("/home/good/getCategory", data);
};
// 商品列表
const getGoodList = function (data) {
  return request.post("/home/good/getGoodList", data);
};
//获取验证码
const getCode = function (data) {
  return request.post("/admin/system/getCode", data);
};
//提交预留信息
const creatClient = function (data) {
  return request.post("/admin/system/creatClient", data);
};



const api = {
  home: {
    getHome,
    getCode,
    creatClient
  },
  good: {
    getDetail,
    getCategory,
    getGoodList
  }
};
export default api;